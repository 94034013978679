var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-main-container" }, [
    _c("div", { staticClass: "headline" }, [_vm._v("チェックインラリー")]),
    !_vm.checkinAuth
      ? _c("div", { attrs: { id: "search-results" } }, [_vm._v("非公開")])
      : _c("div", { attrs: { id: "main-content" } }, [
          _c("div", { staticClass: "checkin-point" }, [
            _vm._v("チェックインポイント : " + _vm._s(_vm.checkinPoint))
          ]),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.checkInRallyList, function(item, index) {
              return _c(
                "div",
                { staticClass: "col-6 col-md-3", attrs: { value: item } },
                [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card-body",
                        class: {
                          notyet: item.satus == "NOT_YET",
                          started: item.satus == "STARTED",
                          completed: item.satus == "COMPLETED"
                        }
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(item.outline))
                        ]),
                        _c("div", { staticClass: "icon" }, [
                          _c("img", {
                            attrs: { src: _vm.getIconPath(item.satus), alt: "" }
                          })
                        ]),
                        _c("div", { staticClass: "count" }, [
                          _vm._v(
                            _vm._s(item.count) +
                              " / " +
                              _vm._s(item.rockyIdList.length)
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            }),
            0
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }