<template lang="pug">
  .tab-main-container
    .headline チェックインラリー
    #search-results(v-if="!checkinAuth") 非公開
    #main-content(v-else)
      .checkin-point チェックインポイント : {{checkinPoint}}
      .row
        .col-6.col-md-3(v-for="(item,index) in checkInRallyList" :value="item")
          //-ユーザーのレベル以下のステータスバッジを表示する
          .card
            .card-body(:class="{'notyet':item.satus=='NOT_YET','started':item.satus=='STARTED','completed':item.satus=='COMPLETED'}")
              .title {{item.title}}
              .text {{item.outline}}
              .icon
                img(:src="getIconPath(item.satus)" alt="")
              .count {{item.count}} / {{item.rockyIdList.length}}
</template>

<script>
export default {
  // データオブジェクト
  data() {
    return {
      checkinRockyTotalCnt:this.$parent.checkinRockyTotalCnt,
      checkinRockTotalCnt:this.$parent.checkinRockTotalCnt,
      checkinPoint:0,
      checkInRallyList:this.$parent.checkInRallyList,
      checkinAuth:Boolean(this.$parent.checkinAuth),
      NOT_YET_ICON_PATH:process.env.VUE_APP_MATERIAL_URL + '/img/icon/rallyIcon_not_yet.png',
      STARTED_ICON_PATH:process.env.VUE_APP_MATERIAL_URL + '/img/icon/rallyIcon_started.png',
      COMPLETED_ICON_PATH:process.env.VUE_APP_MATERIAL_URL + '/img/icon/rallyIcon_completed.png',
    }
  },
  watch: {
    //岩場Listが更新された場合
    '$parent.checkinRockyIdList' (to, from) {
      this.createdProcess()
    },
    //岩Listが更新された場合
    '$parent.checkinRockIdList' (to, from) {
      this.createdProcess()
    }
  }, 
  created() {
    this.createdProcess()
  },
  // メソッド
  methods: {
    async createdProcess(){

      this.checkinRockyTotalCnt = this.$parent.checkinRockyTotalCnt
      this.checkinRockTotalCnt = this.$parent.checkinRockTotalCnt
      
      this.checkinPoint = this.checkinRockyTotalCnt + this.checkinRockTotalCnt

      //登攀済みの岩場ID,岩IDを処理に使いやすいようにそれぞれList化する
      let rockyIdMapList = Object.entries(this.$parent.checkinRockyIdList).map(([key, value]) => ({'key': key, 'value': value}))
      let climbedRockyIdList = rockyIdMapList.map(rocky => rocky['key'])
      let rockIdMapList = Object.entries(this.$parent.checkinRockIdList).map(([key, value]) => ({'key': key, 'value': value}))
      let climbedRockIdList = rockIdMapList.map(rocky => rocky['key'])

      this.checkInRallyList = this.$parent.checkInRallyList

      //チェックインラリーの達成数を抽出してセットする
      this.checkInRallyList.forEach((rally) => {
        //項目を追加
        rally.count = 0
        //岩場が対象のラリーの場合
        if(rally.rockyIdList.length > 0){
          //チェックインラリーの制覇対象岩場数
          let wkRockyCount = rally.rockyIdList.length
          //対象のうち何件達成しているか算出
          rally.rockyIdList.forEach((rockyId) => {
            if(climbedRockyIdList.includes(String(rockyId))){
              rally.count ++
            }
          })
        }
        //岩が対象のラリーの場合
        if(rally.rockIdList.length > 0){
          //チェックインラリーの制覇対象岩場数
          let wkRockyCount = rally.rockIdList.length
          //対象のうち何件達成しているか算出
          rally.rockIdList.forEach((rockId) => {
            if(climbedRockIdList.includes(String(rockId))){
              rally.count ++
            }
          })
        }

        //項目追加
        rally.satus = 'NOT_YET'
        //ラリー単位のステータス範囲
        if(rally.count == 0){
          rally.satus = 'NOT_YET'
        }else if(rally.count == rally.rockyIdList.length || rally.count == rally.rockIdList.length){
          rally.satus = 'COMPLETED'
        }else{
          rally.satus = 'STARTED'
        }
      })
    },
    getIconPath(status){
      switch(status) {
        case "NOT_YET":
          return this.NOT_YET_ICON_PATH
        case "COMPLETED":
          return this.COMPLETED_ICON_PATH
        case "STARTED":
          return this.STARTED_ICON_PATH
      }
    }
  },
  // 算出プロパティ
  computed: {
  },
  // コンポーネント
  components: {
  }
}
</script>

<style lang="stylus" scoped>
#search-results
  margin 1rem
.hidden
  background #cfd3d7
  color #606e79
  padding 1rem 2rem
#main-content
  .checkin-point
    font-size 0.8rem
    color #606e79
    padding 0.5rem
  .row
    margin 0
    .col-6,.col-md-3
      padding 0
      .card
        border none
        background #eceeef
        .card-body
          padding 10px
          text-align center
          color #374047
          .title
            font-size 3.5vw
            font-weight 500
            margin-bottom 0
          .text
            padding-left 0.5rem
            font-size 2.5vw
            height 1rem
          .icon
            img 
              width 40%
              height auto
              filter brightness(120%)

          .count
            padding 0.2rem 0
            font-size 5vw
            font-weight 500
        .notyet,.started,.completed
          border 1px solid #fbfaec
          border-radius 10px
          color #42402b
        .notyet
          background #e2e0de
          color #a0a09d
        .started
          background #a3a600
          color #FFF
        .completed
          background #005794
          color #FFF
@media (min-width 992px)

  #main-content
    .row
      .col-6,.col-md-4
        .card
          .card-body
            .title
              font-size 1.2vw
            .text
              font-size 0.8vw
            .count
              font-size 1.7vw

</style>
